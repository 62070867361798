import React, { useState } from "react"
import styled from "styled-components"
import { ContactService } from "../../../api/contacts"
import { ContactInputGroupConfirm } from "../common/ContactInputGroupConfirm"
import { ContactFormSendButton } from "../common/ContactFormSendButton"
import { uploadFile, ContactFileUploadForm } from "../common/ContactFileUploadForm"
import { contact } from "../../../data/contact"

/**
 * Element & Styles
 */
const Form = styled.form`
`

const FormNode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

const HiddenItem = styled.input`
`

/**
 * Props
 */
type State = {
  types: Array<string>
  content: string
  name: string
  email: string
}

type Options = {
  company: string
  tel: string
  address: string
  webSites: Array<string>
  files: Array<HTMLInputElement>
}

type Props = State & Partial<Options>

/**
 * Component
 */
export const ContactEtcDetail: React.VFC<Props> = props => {
  const [isDisabled, setDisabled] = useState(false)
  return (
    <>
      <Form
        name="その他のお問い合わせ"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        action="/contact/etc/accepted"
        onSubmit={
          (e) => {
            return uploadFile(e);
          }
        }
      >
        <FormNode>
          <HiddenItem type="hidden" name="bot-field" />
          <HiddenItem type="hidden" name="form-name" value="その他のお問い合わせ" />

          <ContactInputGroupConfirm
            label={contact.etc.types.title}
            values={[...props.types]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.content.title}
            values={[props.content]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.company.title}
            values={[props.company]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.name.title}
            values={[props.name]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.email.title}
            values={[props.email]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.tel.title}
            values={[props.tel]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.address.title}
            values={[props.address]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.webSites.title}
            values={[...props.webSites]}
          />

          <ContactInputGroupConfirm
            label={contact.etc.files.title}
            values={[...props.files]}
          />

          <ContactFormSendButton isDisabled={isDisabled} />
        </FormNode>
      </Form>
      <ContactFileUploadForm
        type={"etc"}
        name={props.name}
        email={props.email}
        files={[...props.files]}
        setDisabled={setDisabled}
        uploadService={ContactService.uploadToGoogleDrive}
        callback={() => {
          document.querySelector('form').submit();
        }}
      />
    </>
  )
}
