import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../../../template/Layout"
import { invalidRequired } from "../../../utlis/check"

/**
 * Material
 */
import Head from "../../../head"
import { SectionTitle } from "../../common/SectionTitle"
import { ContactContainer } from "../common/ContactContainer"
import { ContactDescription } from "../common/ContactDescription"
import { ContactEtcDetail } from "./ContactEtcDetail"
import { Props as BreadcrumbsData } from "../../common/Breadcrumbs"
import { useOthersFormContext } from "../../../utlis/FormValues"

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const EtcConfirm: React.VFC<Props> = props => {
  const { state } = useOthersFormContext()

  useEffect(() => {
    if (invalidRequired(state.types) || invalidRequired(state.content) || invalidRequired(state.name) || invalidRequired(state.email)) {
      navigate("../")
    }
  }, [])

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head />
      <ContactContainer>
        <SectionTitle title='CONTACT' subTitle='お問い合わせ' />
        <ContactDescription title="その他のお問い合わせ" step={2} />
        <ContactEtcDetail
          types={state.types || []}
          content={state.content || ''}
          company={state.company || ''}
          name={state.name || ''}
          email={state.email || ''}
          tel={state.tel || ''}
          address={state.address || ''}
          webSites={state.webSites || []}
          files={state.files || []}
        />
      </ContactContainer>
    </Layout>
  )
}
